//
// Font Icons
// Icons selected from:
// https://freeui.design/250-essential-icons-set/
// https://www.flaticon.com/
// Font generated using:
// https://icomoon.io/app
// --------------------------------------------------


$xpi-add-circle: '\e900';
$xpi-add-document: '\e901';
$xpi-add-location: '\e902';
$xpi-add: '\e903';
$xpi-announcement: '\e904';
$xpi-arrow-down-circle: '\e905';
$xpi-arrow-down: '\e906';
$xpi-arrow-left-circle: '\e907';
$xpi-arrow-left: '\e908';
$xpi-arrow-right-circle: '\e909';
$xpi-arrow-right: '\e90a';
$xpi-arrow-up-circle: '\e90b';
$xpi-arrow-up: '\e90c';
$xpi-arrows-horizontal: '\e90d';
$xpi-arrows-vertical: '\e90e';
$xpi-bag: '\e90f';
$xpi-basket: '\e910';
$xpi-behance: '\e911';
$xpi-bell: '\e912';
$xpi-bluetooth: '\e913';
$xpi-book: '\e914';
$xpi-bookmark: '\e915';
$xpi-briefcase: '\e916';
$xpi-calculator: '\e917';
$xpi-camera: '\e918';
$xpi-card: '\e919';
$xpi-cart: '\e91a';
$xpi-chat: '\e91b';
$xpi-check-circle: '\e91c';
$xpi-check: '\e91d';
$xpi-clip: '\e91e';
$xpi-close-circle: '\e91f';
$xpi-close: '\e920';
$xpi-cloud-check: '\e921';
$xpi-cloud-download: '\e922';
$xpi-cloud-upload: '\e923';
$xpi-cloud: '\e924';
$xpi-coin: '\e925';
$xpi-coins: '\e926';
$xpi-currency-exchange: '\e927';
$xpi-delete-document: '\e928';
$xpi-delete-folder: '\e929';
$xpi-delete-location: '\e92a';
$xpi-diamond: '\e92b';
$xpi-document-alt: '\e92c';
$xpi-document: '\e92d';
$xpi-dollar-circle: '\e92e';
$xpi-dollar: '\e92f';
$xpi-dribbble: '\e930';
$xpi-earphones: '\e931';
$xpi-edit-alt: '\e932';
$xpi-edit: '\e933';
$xpi-euro-circle: '\e934';
$xpi-euro: '\e935';
$xpi-exit-full-screen: '\e936';
$xpi-eye: '\e937';
$xpi-facebook: '\e938';
$xpi-filter-alt: '\e939';
$xpi-filter: '\e93a';
$xpi-flag: '\e93b';
$xpi-folder: '\e93c';
$xpi-forward: '\e93d';
$xpi-full-screen: '\e93e';
$xpi-gift: '\e93f';
$xpi-globe: '\e940';
$xpi-google: '\e941';
$xpi-hangouts: '\e942';
$xpi-heart-circle: '\e943';
$xpi-heart: '\e944';
$xpi-help: '\e945';
$xpi-home: '\e946';
$xpi-idea: '\e947';
$xpi-image: '\e948';
$xpi-increase: '\e949';
$xpi-instagram: '\e94a';
$xpi-joystick: '\e94b';
$xpi-key: '\e94c';
$xpi-lable: '\e94d';
$xpi-laptop: '\e94e';
$xpi-lightning: '\e94f';
$xpi-link: '\e950';
$xpi-linkedin: '\e951';
$xpi-list: '\e952';
$xpi-loading: '\e953';
$xpi-location: '\e954';
$xpi-locked: '\e955';
$xpi-mail: '\e956';
$xpi-map: '\e957';
$xpi-menu-circle: '\e958';
$xpi-menu: '\e959';
$xpi-message: '\e95a';
$xpi-messenger: '\e95b';
$xpi-mobile: '\e95c';
$xpi-money-bag: '\e95d';
$xpi-money-box: '\e95e';
$xpi-moon: '\e95f';
$xpi-music: '\e960';
$xpi-navigation: '\e961';
$xpi-odnoklassniki: '\e962';
$xpi-package: '\e963';
$xpi-percent: '\e964';
$xpi-phone: '\e965';
$xpi-pinterest: '\e966';
$xpi-plane: '\e967';
$xpi-play-circle: '\e968';
$xpi-play: '\e969';
$xpi-reduce: '\e96a';
$xpi-reload: '\e96b';
$xpi-remove-document: '\e96c';
$xpi-remove-folder: '\e96d';
$xpi-reply: '\e96e';
$xpi-rocket: '\e96f';
$xpi-ruler: '\e970';
$xpi-scroll: '\e971';
$xpi-search: '\e972';
$xpi-security-announcement: '\e973';
$xpi-security-check: '\e974';
$xpi-security-close: '\e975';
$xpi-security-prohibition: '\e976';
$xpi-send: '\e977';
$xpi-server-alt: '\e978';
$xpi-server: '\e979';
$xpi-settings: '\e97a';
$xpi-share-alt: '\e97b';
$xpi-share: '\e97c';
$xpi-skype: '\e97d';
$xpi-smile: '\e97e';
$xpi-star-filled: '\e97f';
$xpi-star: '\e980';
$xpi-sun: '\e981';
$xpi-support: '\e982';
$xpi-target: '\e983';
$xpi-telegram: '\e984';
$xpi-thumb-up: '\e985';
$xpi-time: '\e986';
$xpi-trash: '\e987';
$xpi-tumblr: '\e988';
$xpi-turn-off: '\e989';
$xpi-twitter: '\e98a';
$xpi-unlocked: '\e98b';
$xpi-user-circle: '\e98c';
$xpi-user: '\e98d';
$xpi-viber: '\e98e';
$xpi-video: '\e98f';
$xpi-view-grid: '\e990';
$xpi-view-list: '\e991';
$xpi-vimeo: '\e992';
$xpi-vk: '\e993';
$xpi-wallet: '\e994';
$xpi-watch: '\e995';
$xpi-weather: '\e996';
$xpi-wechat: '\e997';
$xpi-youtube-outline: '\e998';
$xpi-youtube: '\e999';
$xpi-caret: '\e99a';
$xpi-paypal: '\e99b';
$xpi-delivery: '\e99c';
$xpi-loudspeaker: '\e99d';
$xpi-sign-in: '\e99e';
$xpi-sign-out: '\e99f';
$xpi-wash: '\e9a0';
$xpi-bleach: '\e9a1';
$xpi-hand-wash: '\e9a2';
$xpi-ironing: '\e9a3';
$xpi-dry-clean: '\e9a4';
$xpi-add-user: '\e9a5';
$xpi-compare: '\e9a6';
$xpi-monitor: '\e9a7';
$xpi-printer: '\e9a8';
$xpi-speaker: '\e9a9';
$xpi-download: '\e9aa';
$xpi-upload: '\e9ab';
$xpi-bluetooth-circle: '\e9ac';
$xpi-battery: '\e9ad';
$xpi-sound-waves: '\e9ae';
$xpi-truck: '\e9be';
$xpi-tag: '\e9bf';
$xpi-discount: '\e9c3';
$xpi-open: '\e9c4';
$xpi-star-half: '\e9d5';
$xpi-eye-off: '\e9d6';
$xpi-thumb-up-filled: '\e9d7';
$xpi-dot-square: '\e9d8';
$xpi-discord: '\e9d9';
$xpi-tiktok: '\e9da';
$xpi-date: '\e9af';


@font-face {
  font-family: '#{$icons-font-family}';
  src:
    url('#{$icons-font-path}/#{$icons-font-family}.ttf?z88dg8') format('truetype'),
    url('#{$icons-font-path}/#{$icons-font-family}.woff?z88dg8') format('woff'),
    url('#{$icons-font-path}/#{$icons-font-family}.svg?z88dg8##{$icons-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='xpi-'], [class*=' xpi-'] {
  display: inline-block;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icons-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.xpi-add-circle {
  &:before {
    content: $xpi-add-circle; 
  }
}
.xpi-add-document {
  &:before {
    content: $xpi-add-document; 
  }
}
.xpi-add-location {
  &:before {
    content: $xpi-add-location; 
  }
}
.xpi-add {
  &:before {
    content: $xpi-add; 
  }
}
.xpi-announcement {
  &:before {
    content: $xpi-announcement; 
  }
}
.xpi-arrow-down-circle {
  &:before {
    content: $xpi-arrow-down-circle; 
  }
}
.xpi-arrow-down {
  &:before {
    content: $xpi-arrow-down; 
  }
}
.xpi-arrow-left-circle {
  &:before {
    content: $xpi-arrow-left-circle; 
  }
}
.xpi-arrow-left {
  &:before {
    content: $xpi-arrow-left; 
  }
}
.xpi-arrow-right-circle {
  &:before {
    content: $xpi-arrow-right-circle; 
  }
}
.xpi-arrow-right {
  &:before {
    content: $xpi-arrow-right; 
  }
}
.xpi-arrow-up-circle {
  &:before {
    content: $xpi-arrow-up-circle; 
  }
}
.xpi-arrow-up {
  &:before {
    content: $xpi-arrow-up; 
  }
}
.xpi-arrows-horizontal {
  &:before {
    content: $xpi-arrows-horizontal; 
  }
}
.xpi-arrows-vertical {
  &:before {
    content: $xpi-arrows-vertical; 
  }
}
.xpi-bag {
  &:before {
    content: $xpi-bag; 
  }
}
.xpi-basket {
  &:before {
    content: $xpi-basket; 
  }
}
.xpi-behance {
  &:before {
    content: $xpi-behance; 
  }
}
.xpi-bell {
  &:before {
    content: $xpi-bell; 
  }
}
.xpi-bluetooth {
  &:before {
    content: $xpi-bluetooth; 
  }
}
.xpi-book {
  &:before {
    content: $xpi-book; 
  }
}
.xpi-bookmark {
  &:before {
    content: $xpi-bookmark; 
  }
}
.xpi-briefcase {
  &:before {
    content: $xpi-briefcase; 
  }
}
.xpi-calculator {
  &:before {
    content: $xpi-calculator; 
  }
}
.xpi-camera {
  &:before {
    content: $xpi-camera; 
  }
}
.xpi-card {
  &:before {
    content: $xpi-card; 
  }
}
.xpi-cart {
  &:before {
    content: $xpi-cart; 
  }
}
.xpi-chat {
  &:before {
    content: $xpi-chat; 
  }
}
.xpi-check-circle {
  &:before {
    content: $xpi-check-circle; 
  }
}
.xpi-check {
  &:before {
    content: $xpi-check; 
  }
}
.xpi-clip {
  &:before {
    content: $xpi-clip; 
  }
}
.xpi-close-circle {
  &:before {
    content: $xpi-close-circle; 
  }
}
.xpi-close {
  &:before {
    content: $xpi-close; 
  }
}
.xpi-cloud-check {
  &:before {
    content: $xpi-cloud-check; 
  }
}
.xpi-cloud-download {
  &:before {
    content: $xpi-cloud-download; 
  }
}
.xpi-cloud-upload {
  &:before {
    content: $xpi-cloud-upload; 
  }
}
.xpi-cloud {
  &:before {
    content: $xpi-cloud; 
  }
}
.xpi-coin {
  &:before {
    content: $xpi-coin; 
  }
}
.xpi-coins {
  &:before {
    content: $xpi-coins; 
  }
}
.xpi-currency-exchange {
  &:before {
    content: $xpi-currency-exchange; 
  }
}
.xpi-delete-document {
  &:before {
    content: $xpi-delete-document; 
  }
}
.xpi-delete-folder {
  &:before {
    content: $xpi-delete-folder; 
  }
}
.xpi-delete-location {
  &:before {
    content: $xpi-delete-location; 
  }
}
.xpi-diamond {
  &:before {
    content: $xpi-diamond; 
  }
}
.xpi-document-alt {
  &:before {
    content: $xpi-document-alt; 
  }
}
.xpi-document {
  &:before {
    content: $xpi-document; 
  }
}
.xpi-dollar-circle {
  &:before {
    content: $xpi-dollar-circle; 
  }
}
.xpi-dollar {
  &:before {
    content: $xpi-dollar; 
  }
}
.xpi-dribbble {
  &:before {
    content: $xpi-dribbble; 
  }
}
.xpi-earphones {
  &:before {
    content: $xpi-earphones; 
  }
}
.xpi-edit-alt {
  &:before {
    content: $xpi-edit-alt; 
  }
}
.xpi-edit {
  &:before {
    content: $xpi-edit; 
  }
}
.xpi-euro-circle {
  &:before {
    content: $xpi-euro-circle; 
  }
}
.xpi-euro {
  &:before {
    content: $xpi-euro; 
  }
}
.xpi-exit-full-screen {
  &:before {
    content: $xpi-exit-full-screen; 
  }
}
.xpi-eye {
  &:before {
    content: $xpi-eye; 
  }
}
.xpi-facebook {
  &:before {
    content: $xpi-facebook; 
  }
}
.xpi-filter-alt {
  &:before {
    content: $xpi-filter-alt; 
  }
}
.xpi-filter {
  &:before {
    content: $xpi-filter; 
  }
}
.xpi-flag {
  &:before {
    content: $xpi-flag; 
  }
}
.xpi-folder {
  &:before {
    content: $xpi-folder; 
  }
}
.xpi-forward {
  &:before {
    content: $xpi-forward; 
  }
}
.xpi-full-screen {
  &:before {
    content: $xpi-full-screen; 
  }
}
.xpi-gift {
  &:before {
    content: $xpi-gift; 
  }
}
.xpi-globe {
  &:before {
    content: $xpi-globe; 
  }
}
.xpi-google {
  &:before {
    content: $xpi-google; 
  }
}
.xpi-hangouts {
  &:before {
    content: $xpi-hangouts; 
  }
}
.xpi-heart-circle {
  &:before {
    content: $xpi-heart-circle; 
  }
}
.xpi-heart {
  &:before {
    content: $xpi-heart; 
  }
}
.xpi-help {
  &:before {
    content: $xpi-help; 
  }
}
.xpi-home {
  &:before {
    content: $xpi-home; 
  }
}
.xpi-idea {
  &:before {
    content: $xpi-idea; 
  }
}
.xpi-image {
  &:before {
    content: $xpi-image; 
  }
}
.xpi-increase {
  &:before {
    content: $xpi-increase; 
  }
}
.xpi-instagram {
  &:before {
    content: $xpi-instagram; 
  }
}
.xpi-joystick {
  &:before {
    content: $xpi-joystick; 
  }
}
.xpi-key {
  &:before {
    content: $xpi-key; 
  }
}
.xpi-lable {
  &:before {
    content: $xpi-lable; 
  }
}
.xpi-laptop {
  &:before {
    content: $xpi-laptop; 
  }
}
.xpi-lightning {
  &:before {
    content: $xpi-lightning; 
  }
}
.xpi-link {
  &:before {
    content: $xpi-link; 
  }
}
.xpi-linkedin {
  &:before {
    content: $xpi-linkedin; 
  }
}
.xpi-list {
  &:before {
    content: $xpi-list; 
  }
}
.xpi-loading {
  &:before {
    content: $xpi-loading; 
  }
}
.xpi-location {
  &:before {
    content: $xpi-location; 
  }
}
.xpi-locked {
  &:before {
    content: $xpi-locked; 
  }
}
.xpi-mail {
  &:before {
    content: $xpi-mail; 
  }
}
.xpi-map {
  &:before {
    content: $xpi-map; 
  }
}
.xpi-menu-circle {
  &:before {
    content: $xpi-menu-circle; 
  }
}
.xpi-menu {
  &:before {
    content: $xpi-menu; 
  }
}
.xpi-message {
  &:before {
    content: $xpi-message; 
  }
}
.xpi-messenger {
  &:before {
    content: $xpi-messenger; 
  }
}
.xpi-mobile {
  &:before {
    content: $xpi-mobile; 
  }
}
.xpi-money-bag {
  &:before {
    content: $xpi-money-bag; 
  }
}
.xpi-money-box {
  &:before {
    content: $xpi-money-box; 
  }
}
.xpi-moon {
  &:before {
    content: $xpi-moon; 
  }
}
.xpi-music {
  &:before {
    content: $xpi-music; 
  }
}
.xpi-navigation {
  &:before {
    content: $xpi-navigation; 
  }
}
.xpi-odnoklassniki {
  &:before {
    content: $xpi-odnoklassniki; 
  }
}
.xpi-package {
  &:before {
    content: $xpi-package; 
  }
}
.xpi-percent {
  &:before {
    content: $xpi-percent; 
  }
}
.xpi-phone {
  &:before {
    content: $xpi-phone; 
  }
}
.xpi-pinterest {
  &:before {
    content: $xpi-pinterest; 
  }
}
.xpi-plane {
  &:before {
    content: $xpi-plane; 
  }
}
.xpi-play-circle {
  &:before {
    content: $xpi-play-circle; 
  }
}
.xpi-play {
  &:before {
    content: $xpi-play; 
  }
}
.xpi-reduce {
  &:before {
    content: $xpi-reduce; 
  }
}
.xpi-reload {
  &:before {
    content: $xpi-reload; 
  }
}
.xpi-remove-document {
  &:before {
    content: $xpi-remove-document; 
  }
}
.xpi-remove-folder {
  &:before {
    content: $xpi-remove-folder; 
  }
}
.xpi-reply {
  &:before {
    content: $xpi-reply; 
  }
}
.xpi-rocket {
  &:before {
    content: $xpi-rocket; 
  }
}
.xpi-ruler {
  &:before {
    content: $xpi-ruler; 
  }
}
.xpi-scroll {
  &:before {
    content: $xpi-scroll; 
  }
}
.xpi-search {
  &:before {
    content: $xpi-search; 
  }
}
.xpi-security-announcement {
  &:before {
    content: $xpi-security-announcement; 
  }
}
.xpi-security-check {
  &:before {
    content: $xpi-security-check; 
  }
}
.xpi-security-close {
  &:before {
    content: $xpi-security-close; 
  }
}
.xpi-security-prohibition {
  &:before {
    content: $xpi-security-prohibition; 
  }
}
.xpi-send {
  &:before {
    content: $xpi-send; 
  }
}
.xpi-server-alt {
  &:before {
    content: $xpi-server-alt; 
  }
}
.xpi-server {
  &:before {
    content: $xpi-server; 
  }
}
.xpi-settings {
  &:before {
    content: $xpi-settings; 
  }
}
.xpi-share-alt {
  &:before {
    content: $xpi-share-alt; 
  }
}
.xpi-share {
  &:before {
    content: $xpi-share; 
  }
}
.xpi-skype {
  &:before {
    content: $xpi-skype; 
  }
}
.xpi-smile {
  &:before {
    content: $xpi-smile; 
  }
}
.xpi-star-filled {
  &:before {
    content: $xpi-star-filled; 
  }
}
.xpi-star {
  &:before {
    content: $xpi-star; 
  }
}
.xpi-sun {
  &:before {
    content: $xpi-sun; 
  }
}
.xpi-support {
  &:before {
    content: $xpi-support; 
  }
}
.xpi-target {
  &:before {
    content: $xpi-target; 
  }
}
.xpi-telegram {
  &:before {
    content: $xpi-telegram; 
  }
}
.xpi-thumb-up {
  &:before {
    content: $xpi-thumb-up; 
  }
}
.xpi-time {
  &:before {
    content: $xpi-time; 
  }
}
.xpi-trash {
  &:before {
    content: $xpi-trash; 
  }
}
.xpi-tumblr {
  &:before {
    content: $xpi-tumblr; 
  }
}
.xpi-turn-off {
  &:before {
    content: $xpi-turn-off; 
  }
}
.xpi-twitter {
  &:before {
    content: $xpi-twitter; 
  }
}
.xpi-unlocked {
  &:before {
    content: $xpi-unlocked; 
  }
}
.xpi-user-circle {
  &:before {
    content: $xpi-user-circle; 
  }
}
.xpi-user {
  &:before {
    content: $xpi-user; 
  }
}
.xpi-viber {
  &:before {
    content: $xpi-viber; 
  }
}
.xpi-video {
  &:before {
    content: $xpi-video; 
  }
}
.xpi-view-grid {
  &:before {
    content: $xpi-view-grid; 
  }
}
.xpi-view-list {
  &:before {
    content: $xpi-view-list; 
  }
}
.xpi-vimeo {
  &:before {
    content: $xpi-vimeo; 
  }
}
.xpi-vk {
  &:before {
    content: $xpi-vk; 
  }
}
.xpi-wallet {
  &:before {
    content: $xpi-wallet; 
  }
}
.xpi-watch {
  &:before {
    content: $xpi-watch; 
  }
}
.xpi-weather {
  &:before {
    content: $xpi-weather; 
  }
}
.xpi-wechat {
  &:before {
    content: $xpi-wechat; 
  }
}
.xpi-youtube-outline {
  &:before {
    content: $xpi-youtube-outline; 
  }
}
.xpi-youtube {
  &:before {
    content: $xpi-youtube; 
  }
}
.xpi-caret {
  &:before {
    content: $xpi-caret; 
  }
}
.xpi-paypal {
  &:before {
    content: $xpi-paypal; 
  }
}
.xpi-delivery {
  &:before {
    content: $xpi-delivery; 
  }
}
.xpi-loudspeaker {
  &:before {
    content: $xpi-loudspeaker; 
  }
}
.xpi-sign-in {
  &:before {
    content: $xpi-sign-in; 
  }
}
.xpi-sign-out {
  &:before {
    content: $xpi-sign-out; 
  }
}
.xpi-wash {
  &:before {
    content: $xpi-wash; 
  }
}
.xpi-bleach {
  &:before {
    content: $xpi-bleach; 
  }
}
.xpi-hand-wash {
  &:before {
    content: $xpi-hand-wash; 
  }
}
.xpi-ironing {
  &:before {
    content: $xpi-ironing; 
  }
}
.xpi-dry-clean {
  &:before {
    content: $xpi-dry-clean; 
  }
}
.xpi-add-user {
  &:before {
    content: $xpi-add-user; 
  }
}
.xpi-compare {
  &:before {
    content: $xpi-compare; 
  }
}
.xpi-monitor {
  &:before {
    content: $xpi-monitor; 
  }
}
.xpi-printer {
  &:before {
    content: $xpi-printer; 
  }
}
.xpi-speaker {
  &:before {
    content: $xpi-speaker; 
  }
}
.xpi-download {
  &:before {
    content: $xpi-download; 
  }
}
.xpi-upload {
  &:before {
    content: $xpi-upload; 
  }
}
.xpi-bluetooth-circle {
  &:before {
    content: $xpi-bluetooth-circle; 
  }
}
.xpi-battery {
  &:before {
    content: $xpi-battery; 
  }
}
.xpi-sound-waves {
  &:before {
    content: $xpi-sound-waves; 
  }
}
.xpi-truck {
  &:before {
    content: $xpi-truck; 
  }
}
.xpi-tag {
  &:before {
    content: $xpi-tag; 
  }
}
.xpi-discount {
  &:before {
    content: $xpi-discount; 
  }
}
.xpi-open {
  &:before {
    content: $xpi-open; 
  }
}
.xpi-star-half {
  &:before {
    content: $xpi-star-half; 
  }
}
.xpi-eye-off {
  &:before {
    content: $xpi-eye-off; 
  }
}
.xpi-thumb-up-filled {
  &:before {
    content: $xpi-thumb-up-filled; 
  }
}
.xpi-dot-square {
  &:before {
    content: $xpi-dot-square; 
  }
}
.xpi-discord {
  &:before {
    content: $xpi-discord; 
  }
}
.xpi-tiktok {
  &:before {
    content: $xpi-tiktok; 
  }
}
.xpi-date {
  &:before {
    content: $xpi-date; 
  }
}