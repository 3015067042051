//
// Navbar
// --------------------------------------------------


// Navbar brand

.navbar-brand {
  display: inline-block;
  font-weight: $navbar-brand-font-weight;
  vertical-align: middle;
  > img {
    display: block;
  }
}


// Navbar nav

.navbar-nav {
  .nav-item {
    margin-bottom: $navbar-nav-link-padding-y * .667;
    border-radius: $border-radius;

    > .dropdown-menu > li > .dropdown-item {
      font-weight: $font-weight-medium;
      & > * { font-weight: $font-weight-normal; }
    }
  }
  .nav-link {
    padding:  $navbar-nav-link-padding-y * .667 $navbar-nav-link-padding-x;
    font-weight: $font-weight-medium;
  }
  /* Note: Bootstrap 5 menu changes. Commented out.
  .dropdown-menu {
    min-width: $navbar-dropdown-min-width;
    padding-top: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;

    .dropdown-menu {
      width: calc(100% - (#{$spacer} * 2));
      margin: 0 $spacer;
      &:not(.animation-none) {
        display: block;
        opacity: 1;
      }
    }
    .dropdown-toggle {
      &::after { display: none; }
    }
  }
  */
}


// Disbale sticky/fixed navbar on handheld devices
.navbar.fixed-top {
  position: relative;
}


// Mega navigation (departments)

.navbar-mega-nav {
  position: relative;
  &::after {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: $border-width;
    height: 1.875rem;
    content: '';
  }
}
.mega-dropdown-column {
  width: 100%;
  max-width: $navbar-mega-dropdown-column-width;
}
.navbar-mega-nav .dropdown-menu {
  > .mega-dropdown { position: static; }
  > .dropdown {
    > a {
      padding: {
        top: .75rem;
        bottom: .75rem;
      }
      border-bottom: $dropdown-border-width solid $dropdown-border-color;
    }
  }
}


// Navbar tools

.navbar-tool {
  position: relative;
  display: flex;
  align-items: center;
  &.dropdown::before {
    position: absolute;
    bottom: -$spacer;
    left: -10%;
    width: 110%;
    height: $spacer;
    content: '';
  }
  .dropdown-menu {
    top: 100%;
    margin-top: $spacer * .5 !important;
    &.dropdown-menu-end {
      left: auto;
      right: 0;
    }
  }
  .navbar-tool-label {
    position: absolute;
    top: -($navbar-tool-label-size * .25);
    right: -($navbar-tool-label-size * .25);
    width: $navbar-tool-label-size;
    height: $navbar-tool-label-size;
    border-radius: 50%;
    background-color: $navbar-tool-label-bg;
    color: $navbar-tool-label-color;
    font: {
      size: $navbar-tool-label-font-size;
      weight: $navbar-tool-label-font-weight;
    }
    text-align: center;
    line-height: $navbar-tool-label-size;
  }
  .navbar-tool-tooltip { display: none; }
  @include media-breakpoint-up(lg) {
    .navbar-tool-tooltip {
      display: block;
      position: absolute;
      top: -.5rem;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity .2s ease-in-out, top .2s ease-in-out;
      padding: .0625rem .375rem;
      border-radius: $border-radius-sm;
      background-color: $tooltip-bg;
      color: $tooltip-color;
      font-size: .6875rem;
      white-space: nowrap;
      opacity: 0;
    }
    &:hover .navbar-tool-tooltip {
      top: -.75rem;
      opacity: .9;
    }
  }
}
.navbar-tool-icon-box {
  position: relative;
  width: $navbar-tool-icon-box-size;
  height: $navbar-tool-icon-box-size;
  transition: $navbar-tool-transition;
  border-radius: 50%;
  line-height: $navbar-tool-icon-box-size - .25rem;
  text-align: center;
  
  &.dropdown-toggle::after { display: none; }
}
.navbar-tool-icon {
  font-size: $navbar-tool-icon-font-size;
  line-height: $navbar-tool-icon-box-size;
}
.navbar-tool-text {
  display: none;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: .875rem;
  transition: $navbar-tool-transition;
  font-size: $navbar-tool-text-font-size;
  text-decoration: none !important;
  white-space: nowrap;
  > small {
    display: block;
    margin-bottom: -.125rem;
  }
}
.dropdown .navbar-tool-text {
  &::after {
    display: inline-block;
    margin-left: .35rem;
    font: {
      family: $icons-font-family;
      size: .7em;
      weight: normal;
    }
    vertical-align: middle;
    content: $xpi-arrow-down;
  }
}

@include media-breakpoint-down(md) {
  .search-box { display: none; }
}


// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        &.fixed-top { position: fixed; }
        .navbar-collapse {
          width: auto;
          flex-basis: auto;
        }
        .navbar-nav {
          .nav-item {
            margin-bottom: 0;
            background-color: transparent;
            > .dropdown-menu > li > .dropdown-item {
              font-weight: normal;
            }
          }
          .nav-link {
            padding: {
              top: $navbar-nav-link-padding-y + .125rem;
              bottom: $navbar-nav-link-padding-y - .125rem;
            }
            font-weight: normal;
          }
        }
        // .navbar-nav:not(.navbar-mega-nav) .nav-link.dropdown-toggle::after {
        //   display: none;
        // }
        .navbar-mega-nav {
          &::after { display: block; }
          .dropdown-menu > .dropdown .dropdown-menu {
            top: .35rem;
          }
          .dropdown-menu > .mega-dropdown {
            .dropdown-menu {
              top: .375rem;
              min-height: 100%;
              min-height: calc(100% + .25rem);
              animation: none !important;
            }
          }
          .dropdown-menu  > .dropdown:last-child > a {
            border-bottom: 0;
          }
        }
        .dropdown-menu {
          margin-top: 0;
          padding-top: $dropdown-padding-y;
          &.dropdown-menu-dark {
            border: $border-width solid $border-light-color;
            background-color: $dropdown-dark-bg;
          }
          &.dropdown-menu-end {
            left: auto;
            right: 0;
          }
          /* Note: Bootstrap 5 menu changes. Commented out.
          &.show { display: none; }
          > .dropdown {
            > .dropdown-toggle {
              position: relative;
              padding-right: $dropdown-item-padding-x * 1.5;
              &::after {
                position: absolute;
                display: block;
                top: 50%;
                right: 1rem;
                margin-top: -.8em;
                font: {
                  family: $icons-font-family;
                  size: .7em;
                  weight: normal;
                }
                content: $xpi-arrow-right;
              }
            }
            .dropdown-menu {
              display: none;
              top: 0;
              right: auto;
              left: 100%;
              width: auto;
              margin: {
                top: -$dropdown-padding-y;
                right: 0;
                left: -.25rem;
              }
            }
            &:hover > .dropdown-menu {
              animation: fade-in .25s ease-in-out;
            }
          }
          */

          &:not(.dropdown-menu-dark) {
            @if $enable-shadows {
              border-color: $dropdown-bg;
            }
            background-color: $dropdown-bg;
            box-shadow: $dropdown-box-shadow;
            .dropdown-menu {
              background-color: $dropdown-bg;
            }
          }
        }
        /* Note: Bootstrap 5 menu changes. Commented out to prevent initial hover to force toggle.
        .dropdown:hover > .dropdown-menu {
          display: block;
          animation: slide-up .25s ease-in-out;
        }
        */
        .navbar-tool-text { display: block; }
        .mega-dropdown-column {
          width: $navbar-mega-dropdown-column-width;
        }
      }
    }
  }
}


// Sticky navbar

.navbar-stuck-toggler { display: none; }
@include media-breakpoint-up(lg) {
  .navbar-sticky.navbar-stuck {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: navbar-show .25s;
    box-shadow: $navbar-stuck-box-shadow;
    z-index: $zindex-fixed;

    .navbar-stuck-toggler { display: flex; }
    .navbar-stuck-barnd { display: block !important; }
    .navbar-stuck-menu {
      display: none;
      &.show { display: block; }
    }
    &.right-15 {
      right: 15px;
      width: calc(100% - 15px);
    }
  }
}

.fixed-top.right-15,
.fixed-bottom.right-15 { right: 15px; }

@keyframes navbar-show {
  from {
    transform: translateY(-.625rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


// Navbar themes

// Dark links against a light background
.navbar-light {
  .nav-link > i {
    transition: $nav-link-transition;
    color: $gray-600;
  }
  .nav-item {
    background-color: $gray-100;
    &:hover .nav-link:not(.disabled),
    &:hover .nav-link:not(.disabled) > i {
      color: $navbar-light-hover-color;
    }
    &.active .nav-link:not(.disabled) > i,
    &.show .nav-link:not(.disabled) > i,
    &.dropdown .nav-link:focus:not(.disabled) > i,
    &.active .nav-link:not(.disabled),
    &.show .nav-link:not(.disabled),
    &.dropdown .nav-link:focus:not(.disabled) {
      color: $navbar-light-active-color;
    }
  }
  .navbar-tool-icon-box {
    color: $navbar-light-tool-color;
  }
  .navbar-tool:hover {
    .navbar-tool-icon-box {
      color: $navbar-light-tool-hover-color;
    }
  }
  .navbar-tool-text {
    color: $navbar-light-tool-color;
    > small {
      color: $navbar-light-tool-text-small-color;
    }
  }
  .navbar-tool:hover .navbar-tool-text {
    color: $navbar-light-tool-hover-color;
  }
  .navbar-mega-nav::after {
    background-color: $border-color;
  }
}

// White links against a dark background
.navbar-dark {
  .nav-link > i {
    transition: $nav-link-transition;
    color: rgba($white, .5);
  }
  .nav-item {
    background-color: rgba($white, .05);
    &:hover .nav-link:not(.disabled) {
      color: $navbar-dark-hover-color;
    }
    &.active .nav-link:not(.disabled) > i,
    &.show .nav-link:not(.disabled) > i,
    &.dropdown .nav-link:focus:not(.disabled) > i,
    &.active .nav-link:not(.disabled),
    &.show .nav-link:not(.disabled),
    &.dropdown .nav-link:focus:not(.disabled) {
      color: $navbar-dark-active-color;
    }
  }
  .navbar-tool-icon-box {
    color: $navbar-dark-tool-color;
    &.bg-secondary { background-color: rgba($white, .08) !important; }
  }
  .navbar-tool-text {
    color: $navbar-dark-tool-color;
    > small {
      color: $navbar-dark-tool-text-small-color;
    }
  }
  .navbar-tool:hover .navbar-tool-text {
    color: $navbar-dark-tool-hover-color;
  }
  .navbar-mega-nav::after {
    background-color: $border-light-color;
  }
}


// Topbar

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $topbar-padding-y 0;
  font-size: $topbar-font-size;
  > .container,
  > .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .topbar-text,
  .topbar-link {
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none !important;
    > i {
      margin-right: .375rem;
      font-size: 1.15em;
      vertical-align: middle;
    }
  }

  // Carousel inside topbar
  .tns-carousel-wrapper {
    [data-controls] {
      margin-top: -1rem;
      width: 2rem;
      height: 2rem;
      transition: color .2s ease-in-out;
      border: 0;
      background: 0;
      font-size: $carousel-control-size-lg * .3;
      line-height: 2rem;
    }
    [data-controls='prev'] { left: -2.2rem; }
    [data-controls='next'] { right: -2.2rem; }
  }
}
@-moz-document url-prefix() {
  .topbar .tns-carousel-wrapper [data-controls] {
    margin-top: -.875rem;
  }
}

// Dark links against a light background
.topbar-light {
  .topbar-text,
  .topbar-link {
    color: $topbar-light-color;
    > i { color: $topbar-light-icon-color; }
    &.text-muted,
    > .text-muted { color: $topbar-light-muted-color !important; }
  }
  .topbar-link {
    &:hover { color: $topbar-light-hover-color; }
    &.active { color: $topbar-light-active-color; }
  }
  .show .topbar-link { color: $topbar-light-active-color; }
  .tns-carousel-wrapper [data-controls] {
    color: $topbar-light-color;
    &:hover { color: $topbar-light-hover-color; }
  }
}

// White links against a dark background
.topbar-dark {
  .topbar-text,
  .topbar-link {
    color: $topbar-dark-color;
    > i { color: $topbar-dark-icon-color; }
    &.text-muted,
    > .text-muted { color: $topbar-dark-muted-color !important; }
  }
  .topbar-link {
    &:hover { color: $topbar-dark-hover-color; }
    &.active { color: $topbar-dark-active-color; }
  }
  .show .topbar-link { color: $topbar-dark-active-color; }
  .tns-carousel-wrapper [data-controls] {
    color: $topbar-dark-color;
    &:hover { color: $topbar-dark-hover-color; }
  }
}

/* Note: Bootstrap 5 menu changes. Override bootstrap styles */
.navbar {
  .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
  @include media-breakpoint-down(lg) {
    .dropend .dropdown-menu[data-bs-popper] {
      margin-left: $spacer;
    }
  }
}

/* Note: Bootstrap 5 menu changes. New styling. */
.navbar-nav:not(.navbar-toolbar) {
  .nav-item {
    .nav-link {
      + .dropdown-menu {
        margin-left: $spacer;
      }
    }
  }
  .dropdown-item {    
    padding-top: $dropdown-item-padding-y * 1.75;
    padding-bottom: $dropdown-item-padding-y * 1.75;

  }
  .dropdown-toggle {
    position: relative;
    padding-right: $dropdown-item-padding-x * 1.5;
    &::after {
      position: absolute;
      display: block;
      top: 50%;
      right: 1rem;
      margin-top: -.8em;
      font: {
        family: $icons-font-family;
        size: .7em;
        weight: normal;
      }
      content: $xpi-arrow-down;
    }
  }
  @include media-breakpoint-down(lg) {
    .dropdown-toggle.show {
      &::after {
        content: $xpi-arrow-up;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      .dropdown-toggle.show {
        &::after {
          content: $xpi-arrow-up;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      .dropdown-toggle {
        &::after {
          content: $xpi-arrow-right;
        }
      }
    }
  }
}