//
// Search results styles
// --------------------------------------------------

.xp-search-results img {
  width: 100px;
}

// Search result item title

.search-result-item-title > a {
  transition: $search-result-item-transition;
  color: $search-result-item-title-link-color;
  font-size: $search-result-item-font-size;
  text-decoration: none !important;
  &:hover {
    color: $search-result-item-hover-color;
  }
}
