//
// Xpect Web Components
// --------------------------------------------------

/* Bootstrap 5 doesn't have form-group from Bootstrap 4 */
.form-group {
    margin-bottom: 1rem;
}

// label {
//     margin-bottom: 0.5rem;
// }


/* ==========================================================================
   Xpect styles
   ========================================================================== */

/* Layout margin/padding
   ========================================================================== */

.xp-margin-small {
    margin: 0 0 3rem 0;
}

@media (min-width: 992px) {
    .xp-margin-small {
        margin: 0 0 8rem 0;
    }
}

/* ==========================================================================
   Forms
   ========================================================================== */

// Form Validation

input.input-validation-error,
input.input-validation-error:focus,
.input-validation-error + button.dropdown-toggle /* for bs-select dropdowns */,
.input-validation-error + button.dropdown-toggle:focus /* for bs-select dropdowns */,
.input-validation-error + div div div button.dropdown-toggle /* for the custom date picker */,
.input-validation-error + div div div button.dropdown-toggle:focus /* for the custom date picker */,
.input-validation-error + div div select /* for the custom date picker */,
.input-validation-error + div div select:focus /* for the custom date picker */ {
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: red !important;
    padding-left: 15px;
}

// Forms 

.xp-page-form .form-control.ng-invalid,
.xp-page-form .form-select.ng-invalid {
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: red !important;
    padding-left: 15px;
}

.xp-page-form .xpect-web-form-component.ng-invalid .xpect-web-form-component-radio {
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: red !important;
    padding-left: 15px;
    border-radius: 5px;
}

/* ==========================================================================
   Shopping Cart / Widget
   ========================================================================== */

.xp-page-shopping-cart .form-control.ng-invalid,
.xp-page-shopping-cart .form-select.ng-invalid {
    border-left-width: 5px !important;
    border-left-style: solid !important;
    border-left-color: red !important;
    padding-left: 15px;
}

/* ==========================================================================
   News
   ========================================================================== */
.xp-news-image-container {
    max-height: 360px;
}

.xp-news-image-container img {
    display: block;
    max-height: inherit !important;
    width: inherit !important;
    max-width: 100% !important;
    margin: 20px auto;
}

/* ==========================================================================
   Contact Forms
   ========================================================================== */

// Contact Forms on Blue Background (bg-accent) need white text
.bg-accent .xp-wc-contact-form {
    // inputs
    input,
    textarea {
        color: #fff;
        ::placeholder {
            color: #bbb;
        }
    }    
    // toggle switch labels
    .options-list {
        label {
            color: #fff;
        }
    }
}
