//
// Xpect
// --------------------------------------------------

@import 'xpect/web-components';

//
// PrimeNG overrides
// --------------------------------------------------
// buttons
.p-button,
.p-selectbutton .p-button.p-highlight {
    background: $primary;
    border-color: $primary;
    &:hover {
        background: lighten($primary, 8%);
        border-color: lighten($primary, 8%);
    }
}
// autocomplete
.p-autocomplete-dd .p-autocomplete-input {
    padding-left: 1rem;
    border: 1px solid $gray-300;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    font-size: $font-size-base;
    font-family: $font-family-sans-serif;
    color: $gray-700;
}
// input switch
.p-inputswitch.p-inputswitch-checked {
    .p-inputswitch-slider {
        background: $primary;
        &:hover {
            background: lighten($primary, 8%);
        }
    }
    &:not(.p-disabled):hover {
        .p-inputswitch-slider {
            background: lighten($primary, 8%);
        }
    }
}
// remove focus highlight
.p-button:focus,
.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
}
// focus border
.p-inputtext:enabled:focus {
    border-color: #f5b680;
    box-shadow: inset 0 1px 2px transparent, 0 0.375rem 0.625rem -0.3125rem rgb(235 109 0 / 15%);
}


//
// Xpect Web Components
// --------------------------------------------------

// messages
.xp-wc-messages {
    .alert {
        margin-top: 1em;
        padding: 1em 0 !important;
        color: $gray-900;
        background-color: $card-bg;
        background-clip: border-box;
        border: $card-border-width solid $card-border-color;
        border-radius: $card-border-radius;
        // info alert
        &.alert-info {
            i {
                color: $info;
            }
        }
        &.alert-danger {
            i {
                color: $danger;
            }
        }
        &.alert-success {
            i {
                color: $success;
            }
        }
        &.alert-warning {
            i {
                color: $warning;
            }
        }
    }
}
