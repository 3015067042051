//
// Navs (Navigation components)
// --------------------------------------------------


// Disble pointer events for active nav links
.nav-link:not(.dropdown-toggle).active {
  pointer-events: none;
}


// Nav tabs

.nav-tabs {
  &:not(.card-header-tabs) {
    margin-bottom: ($spacer * 1.25);
  }
  &.card-header-tabs {
    margin: {
      top: -$card-spacer-x * .25;
      right: -$card-spacer-x;
      left: -$card-spacer-x;
    }
    .nav-link.active {
      background-color: $nav-tabs-link-active-bg;
      border-bottom-color: $nav-tabs-link-active-border-color;
    }
  }
  .nav-link {
    position: relative;
    border: {
      top: 0;
      right: 0;
      left: 0;
    }
    &.active {
      border-bottom-color: $nav-tabs-link-active-border-color;
    }
  }
  &.nav-fill,
  &.nav-justified {
    .nav-link::before {
      left: 0;
      width: 100%;
    }    
  }
}


// Media tabs

.media-tabs {
  border: 0;
  .nav-item {
    margin-bottom: 0;
    text-align: left;
  }
  .nav-link {
    padding: ($nav-link-padding-y * .5) ($nav-link-padding-x * .5);
    &::before { display: none !important; }
  }
}
.media-tab-media {
  position: relative;
  width: $media-tab-media-size;
  height: $media-tab-media-size;
  transition: $nav-link-transition;
  border: $media-tab-media-border-width solid $media-tab-media-border-color;
  border-radius: 50%;
  background-color: $media-tab-media-bg;
  color: $media-tab-media-icon-color;
  text-align: center;
  overflow: hidden;
  > i {
    font-size: $media-tab-media-icon-font-size;
    line-height: calc(#{$media-tab-media-size} - (#{$media-tab-media-border-width} * 2));
  }
  > img {
    display: block;
    width: 100%;
    border-radius: 50%;
  }
}
.media-tab-title,
.media-tab-subtitle {
  transition: $nav-link-transition;
}
a.nav-link {
  &:hover {
    .media-tab-media {
      border-color: $media-tab-media-hover-border-color;
      color: $media-tab-media-icon-hover-color;
    }
  }
}
.nav-link.active,
.nav-link.active:hover {
  .media-tab-media {
    border-color: $media-tab-media-active-border-color;
    background-color: $media-tab-media-active-bg;
    color: $media-tab-media-icon-active-color;
    box-shadow: $media-tab-media-active-box-shadow;
  }
  .media-tab-title {
    color: $media-tab-title-active-color !important;
  }
  .media-tab-subtitle {
    color: $media-tab-subtitle-active-color !important;
  }
}
.nav-link.disabled,
.nav-link.completed {
  .media-tab-media {
    background-color: $media-tab-media-disabled-bg;
    color: $media-tab-media-disabled-color;
  }
}
.nav-link.disabled .media-tab-title {
  color: $media-tab-title-disabled-color;
}
.nav-link.completed .media-tab-media {
  overflow: visible;
  &::after {
    position: absolute;
    top: -.175rem;
    right: -.175rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: lighten($success, 40%);
    border: $border-width solid $success;
    color: $success;
    font: {
      family: 'cartzilla-icons';
      size: .625rem;
      weight: $font-weight-medium;
    }
    line-height: 1.25rem;
    content: '\e91d';
  }
}


// Nav pills

.nav-pills {
  .nav-item {
    margin-right: $spacer * .667;
    &:last-child { margin-right: 0; }
  }
  &.flex-column {
    .nav-item {
      margin: {
        right: 0;
        bottom: $spacer * .667;
      }
      &:last-child { margin-bottom: 0; }
    }
  }
  .nav-link {
    padding: {
      top: $nav-pills-padding-y;
      bottom: $nav-pills-padding-y;
    }
    background-color: $nav-pills-link-bg;
    color: $nav-pills-link-color;
    font-size: $nav-pills-font-size;
    &:hover:not(.active):not([aria-expanded=true]),
    &.show {
      color: $nav-pills-link-hover-color;
      background-color: $nav-pills-link-hover-bg;
    }
    &.disabled {
      background-color: lighten($nav-pills-link-bg, 2%);
      color: $nav-link-disabled-color;
      &:hover:not(.active) {
        background-color: transparent;
      }
    }
    &.active {
      box-shadow: 0 .5rem 1.125rem -.5rem rgba($nav-pills-link-active-bg, .9);
    }
    i { margin-top: -.25rem; }
  }
}


// Inside modal

.modal-content .card-header-tabs {
  margin-right: -$modal-header-padding-x;
  margin-bottom: -$modal-header-padding-y;
  margin-left: -$modal-header-padding-x;
}


// Navigation links style class

.nav-link-style {
  color: $nav-link-color;
  > i {
    margin-top: -.125rem;
    vertical-align: middle;
  }
  &:hover { color: $nav-link-hover-color; }
}
.active > .nav-link-style,
.nav-link-style.active {
  color: $nav-link-active-color;
}
.nav-link-style.nav-link-light {
  color: $nav-link-light-color;
  &:hover { color: $nav-link-light-hover-color; }
}
.active > .nav-link-style.nav-link-light,
.nav-link-style.nav-link-light.active {
  color: $nav-link-light-active-color;
}


// Radio tabs

.radio-tab-pane {
  display: none;
  &.active { display: block; }
}
